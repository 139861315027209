<template>
  <aside
    :class="{ 'is-active': isOpen }"
    class="fixed inset-0 z-[1050] pointer-events-none opacity-0 transition-opacity duration-500 [&.is-active]:opacity-100
    [&.is-active]:pointer-events-auto [&.is-active>span]:pointer-events-auto [&.is-active>div]:translate-x-0"
  >
    <span
      class="absolute inset-0 select-none pointer-events-none bg-black/20"
      @click="closeDrawer"
    />
    <div
      class="absolute h-screen p-[24px] right-0 w-full max-w-[600px] flex flex-col gap-[24px] bg-white
    z-[1051] overflow-auto transition-transform duration-500 translate-x-full will-change-transform"
    >
      <div class="flex items-center justify-between gap-[12px]">
        <b class="font-semibold text-[20px] text-[#333333]">Target</b>
        <i
          class="k-close-circle text-[24px] text-[#333333] cursor-pointer hover:text-[#292D32] transition-colors duration-300"
          @click="closeDrawer"
        />
      </div>
      <div class="flex flex-col gap-[8px]">
        <div
          v-for="value, key in profile"
          :key="key"
          class="flex gap-[12px] py-[9px] text-[14px] text-[#333333]"
        >
          <span class="min-w-[144px]">{{ key }}</span>
          <span class="min-w-fit">:</span>
          <span class="w-full">{{ value }}</span>
        </div>
      </div>
      <div
        v-for="product, idx in data.products"
        :key="idx"
        class="border border-[#E2E2E2] rounded-[8px]"
      >
        <label
          :for="product.product_id"
          class="peer group mb-0 py-[14px] pl-[16px] pr-[14px] flex items-center justify-between gap-[10px] cursor-pointer"
        >
          <input
            :id="product.product_id"
            ref="accordion"
            type="checkbox"
            name="product"
            class="hidden peer"
            @input="() => updateDetail(idx, product.product_id)"
          >
          <b class="text-[14px] font-semibold text-black capitalize">{{ product.name }}</b>
          <div class="flex items-center gap-[16px] peer-checked:[&_i]:rotate-180">
            <button
              v-if="JSON.stringify(fields[idx]) !== oldFields[idx]"
              :disabled="loadpost[idx] || disableSaveBtn(idx)"
              class="text-[12px] text-white font-semibold h-[24px] px-[8px] rounded-[4px] bg-[#F95031] hover:bg-[#d84329] active:bg-[#b33a25]
              transition-colors duration-300 border-none outline-none disabled:!bg-[#F8F8F8] disabled:!text-[#333333]"
              @click="() => updateTarget(idx, product.product_id)"
            >Simpan</button>
            <span class="bg-[#F8F8F8] w-[32px] h-[32px] rounded-[6px] grid place-items-center group-hover:bg-[#E2E2E2]/60 group-active:bg-[#E2E2E2] transition duration-300">
              <b-spinner
                v-if="loadpost[idx]"
                small
              />
              <i
                v-else
                class="k-arrow-down-1 text-[#626262] text-[24px] transition-transform duration-500"
              />
            </span>
          </div>
        </label>
        <div class="h-0 peer-has-[:checked]:h-full overflow-hidden transition-all duration-300">
          <div
            v-if="fields.length > 0"
            class="border-t border-[#E2E2E2] py-[20px] px-[16px] flex flex-col gap-[16px] [&>*]:flex [&>*]:gap-[8px] [&_span]:h-[40px]
            [&_span]:items-center [&_span]:flex [&_span]:text-[#333333] [&_span]:text-[14px] [&_span]:min-w-[144px] [&_small]:text-[12px] [&_small]:text-[#E31A1A]
            [&_input]:w-full [&_input]:h-[40px] [&_input]:px-[14px] [&_input]:border-none [&_input]:outline-none [&_input]:text-[#333333] [&_input]:text-[14px]"
          >
            <div>
              <span>Target Leads</span>
              <div
                :class="{ 'is-error': errors[idx].leads !== '' }"
                class="[&.is-error>*]:!border-[#E31A1A] [&.is-error_small]:text-[#E31A1A] w-full flex flex-col gap-[8px]"
              >
                <input
                  :id="`leads-target-${product.product_id}`"
                  v-model="fields[idx].leads"
                  type="text"
                  name="leads-target"
                  title=""
                  :disabled="loadget[idx] || loadpost[idx]"
                  spellcheck="false"
                  placeholder="Enter target leads"
                  class="border border-[#E2E2E2] rounded-[8px]"
                  @keydown="(e) => validateNumberOnly(e, idx, 'leads')"
                  @input="({ target: { value } }) => validateMinMax(value, idx, 'leads')"
                >
                <small v-if="errors[idx].leads !== ''">{{ errors[idx].leads }}</small>
              </div>
              <div
                v-if="parsedOldField(idx).leads !== ''"
                class="h-[40px] flex items-center"
              >
                <i class="k-edit text-[24px] text-[#F95031] font-semiboild" />
              </div>
            </div>
            <div>
              <span>Target CR</span>
              <div
                :class="{ 'is-error': errors[idx].closing_rate !== '' }"
                class="[&.is-error>*]:!border-[#E31A1A] [&.is-error_small]:text-[#E31A1A] w-full flex flex-col gap-[8px]"
              >
                <div class="border border-[#E2E2E2] rounded-[8px] overflow-hidden flex w-full">
                  <input
                    :id="`closing-rate-target-${product.product_id}`"
                    v-model="fields[idx].closing_rate"
                    type="text"
                    name="closing-rate-target"
                    title=""
                    :disabled="loadget[idx] || loadpost[idx]"
                    spellcheck="false"
                    placeholder="Enter the target conversion rate"
                    @keydown="(e) => validateNumberOnly(e, idx, 'closing_rate')"
                    @input="({ target: { value } }) => validateMinMax(value, idx, 'closing_rate')"
                  >
                  <b class="h-[40px] min-w-[39px] border-l border-[#E2E2E2] grid place-items-center text-[14px] text-[#626262] font-normal">%</b>
                </div>
                <small v-if="errors[idx].closing_rate !== ''">{{ errors[idx].closing_rate }}</small>
              </div>
              <div
                v-if="parsedOldField(idx).closing_rate !== ''"
                class="h-[40px] flex items-center"
              >
                <i class="k-edit text-[24px] text-[#F95031] font-semiboild" />
              </div>
            </div>
          </div>
          <div class="py-[16px] px-[14px] border-t border-[#E2E2E2]">
            <div class="flex items-center justify-between gap-[8px] mb-[20px]">
              <b class="text-[16px] font-semibold text-[#333333]">Performance</b>
              <div
                class="month-picker w-[130px] border flex justify-between rounded items-center px-[5px] [&_.month-year-label]:py-[11px]
              [&_.month-year-label]:cursor-pointer [&_.month-year-label]:w-[130px] [&_.month-picker-wrapper]:-translate-x-[6px]
              [&_.month-picker-wrapper]:!min-w-0 [&_.date-popover]:min-w-[200px] [&_.date-popover]:!-translate-x-[calc(50%+14px)]
              [&_.date-popover]:!translate-y-2"
              >
                <MonthlyPicker
                  v-model="filterMonths[idx]"
                  :month-labels="monthlabel"
                  date-format="MMM YYYY"
                  style="width: 90px;"
                  :disabled="loadget[idx] || loadpost[idx]"
                  input-class="border-0 w-[100px] h-full font-medium"
                  :clear-option="false"
                  :alignment="'center'"
                  :selected-background-color="'#F95031'"
                  :max="moment()"
                  @selected="() => updateDetail(idx, product.product_id, true)"
                />
                <b-spinner
                  v-if="loadget[idx]"
                  class="mr-[7px]"
                  small
                />
                <span
                  v-else
                  class="k-calendar text-[24px]"
                />
              </div>
            </div>
            <div
              :class="{ 'animate-pulse': loadget[idx], 'grid place-items-center': isEmptyPerformance(performances[idx]) }"
              class="p-[24px] bg-[#F8F8F8] rounded-[12px] h-[292px] overflow-auto"
            >
              <span
                v-if="isEmptyPerformance(performances[idx])"
                class="text-[#C2C2C2] text-[14px] leading-[21px]"
              >There is no data to display, please input target</span>
              <template v-else>
                <div
                  v-for="item, i in performances[idx]"
                  :key="`${idx}-${i}`"
                  class="flex gap-[12px]"
                >
                  <div class="relative w-[40px] min-h-full">
                    <hr
                      v-if="i < performances[idx].length - 1"
                      class="absolute outline-none border-l-[3px] border-dashed border-[#FBA63C] top-0 h-full left-[calc(50%-1.5px)]"
                    >
                    <span class="relative w-[40px] min-h-[40px] rounded-full bg-[#FCEBBE] text-[#FBA63C] font-semibold grid place-items-center">{{ performances[idx].length - i }}</span>
                  </div>
                  <div class="flex flex-col gap-[12px] w-full">
                    <span class="text-[14px] text-[#626262]">{{ item.date }}</span>
                    <div
                      v-if="parsedOldField(idx).leads === '' || parsedOldField(idx).closing_rate === ''"
                      :class="{ 'mb-[56px]': i !== performances[idx].length - 1 }"
                      class="h-[38px] rounded-[8px] bg-[#FFF2E2] text-[#AF6A13] px-[8px] flex items-center gap-[8px] w-full"
                    >
                      <span class="bg-[#dfbc8f] grid place-items-center pl-[0.5px] w-[16px] h-[16px] rounded-full text-[10px] font-medium">!</span>
                      <span class="text-[12px]">Please enter the target</span>
                    </div>
                    <div
                      v-else
                      :class="{ 'mb-[56px]': i !== performances[idx].length - 1 }"
                      class="bg-white border border-[#E2E2E2] rounded-[8px] overflow-hidden [&>*]:p-[14px] [&>*]:flex [&>*]:gap-[8px] [&_i]:text-[24px]
                  [&_i]:text-[#F95031] [&_i]:font-medium [&_strong]:text-[14px] [&_strong]:font-medium [&_strong]:text-[#333333] [&_b]:text-[#34A770]
                  [&_p]:text-[#626262] [&_p]:text-[12px] [&_p]:mt-[4px] [&_p]:mb-0"
                    >
                      <div class="border-b border-[#E2E2E2]">
                        <i class="k-percentage-square" />
                        <div>
                          <strong>Average CR is <b :style="{ color: item.crColor }">{{ item.crStatus }} target</b></strong>
                          <p>{{ item.crUpdate }}</p>
                        </div>
                      </div>
                      <div>
                        <i :class="`k-trend-${item.leadStatus.startsWith('inc') ? 'up' : 'down'}`" />
                        <div>
                          <strong>Average Total Leads <b :style="{ color: item.leadColor }">{{ item.leadStatus }} target</b></strong>
                          <p>{{ item.leadUpdate }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import moment from 'moment'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import MonthlyPicker from 'vue-monthly-picker'

export default {
  components: { MonthlyPicker },
  props: {
    data: {
      type: Object,
      default: () => ({
        user_id: 0,
        full_name: '',
        talent_lead: '',
        partner_name: '',
        report_id: 0,
        products: [{ product_id: 0, name: '' }],
      }),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [],
      oldFields: [],
      performances: [],
      filterMonths: [],
      loadget: [],
      loadpost: [],
      errors: [],
      isOpened: [],
      alertError,
      alertSuccess,
      moment,
    }
  },
  computed: {
    profile() {
      return ({
        'Nama Talent': this.data.full_name,
        'Talent Lead': this.data.talent_lead,
        Partner: this.data.partner_name,
      })
    },
    errText() {
      return ({
        numOnly: 'Can only enter numbers',
        minOne: 'The minimum value must be 1',
        maxHundred: 'The maximum value must be 100',
      })
    },
    monthlabel() {
      return LABELMONTH
    },
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.openDrawer()
        this.fields = this.data.products.map(() => ({ leads: '', closing_rate: '' }))
        this.oldFields = this.data.products.map(() => JSON.stringify({ leads: '', closing_rate: '' }))
        this.filterMonths = this.data.products.map(() => moment())
        this.performances = this.data.products.map(() => [])
        this.loadget = this.data.products.map(() => false)
        this.loadpost = this.data.products.map(() => false)
        this.isOpened = this.data.products.map(() => false)
        this.errors = this.data.products.map(() => ({ leads: '', closing_rate: '' }))
      }
    },
  },
  methods: {
    openDrawer() {
      document.body.style.overflow = 'hidden'
    },
    closeDrawer() {
      document.body.style.overflow = 'auto'
      this.$emit('update:isOpen', false)
      this.$refs.accordion.forEach(a => {
        if (a.checked) a.click()
      })
    },
    parsedOldField(idx) {
      return JSON.parse(this.oldFields[idx])
    },
    disableSaveBtn(idx) {
      return this.fields[idx].leads === '' || this.fields[idx].closing_rate === '' || this.errors[idx].leads === this.errText.minOne
      || [this.errText.minOne, this.errText.maxHundred].includes(this.errors[idx].closing_rate)
    },
    validateNumberOnly(e, idx, type) {
      const whitelist = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End']
      if (!/\d/.test(e.key) && !whitelist.includes(e.key)) {
        e.preventDefault()
        this.errors[idx][type] = this.errText.numOnly
        return
      }
      if (!whitelist.includes(e.key)) this.errors[idx][type] = ''
    },
    validateMinMax(value, idx, type) {
      if (value !== '') this.fields[idx][type] = (+value).toString()
      if (+value < 1) {
        this.errors[idx][type] = this.errText.minOne
        return
      }
      if (+value > 100 && type === 'closing_rate') {
        this.errors[idx][type] = this.errText.maxHundred
        return
      }
      this.errors[idx][type] = ''
    },
    transformPerformance(data, idx) {
      const init = data.slice(1, data.length)
      return init.map((d, i) => {
        const cr = +this.fields[idx].closing_rate
        const lead = +this.fields[idx].leads

        const crStatus = d.cr >= cr ? 'on' : 'not on'
        const crColor = d.cr >= cr ? '#34A770' : '#E31A1A'

        let crUpdate = 'Equals with Last Week'
        if (d.cr > data[i].cr) crUpdate = 'Increased from Last Week'
        if (d.cr < data[i].cr) crUpdate = 'Decreased from Last Week'
        if (data[i].cr === 0) crUpdate = '-'

        const leadTraffic = ((Math.abs(d.leads - lead) / lead) * 100).toFixed()
        let leadStatus = 'is on'
        if (d.leads > lead) leadStatus = `increased ${leadTraffic}% from`
        if (d.leads < lead) leadStatus = `decreased ${leadTraffic}% from`

        let leadColor = '#333333'
        if (d.leads > lead) leadColor = '#34A770'
        if (d.leads < lead) leadColor = '#FBA63C'

        let leadUpdate = 'Equals with Last Week'
        if (d.leads > data[i].leads) leadUpdate = 'Increased from Last Week'
        if (d.leads < data[i].leads) leadUpdate = 'Decreased from Last Week'
        if (data[i].leads === 0) leadUpdate = '-'

        return {
          ...d, crStatus, crColor, crUpdate, leadStatus, leadColor, leadUpdate,
        }
      }).reverse()
    },
    isEmptyPerformance(data) {
      const vector = data.map(d => [d.leads, d.cr]).flat()
      return vector.every(v => v === 0)
    },
    async updateDetail(idx, id, force = false) {
      if (!force && (!this.$refs.accordion[idx].checked || this.isOpened[idx])) return
      try {
        this.$set(this.loadget, idx, true)
        const params = {
          date: YEAR_MONTH(this.filterMonths[idx]),
          product_id: id,
          user_id: this.data.user_id,
        }
        const { data: { data } } = await komtimAxiosIns.get('v1/talent_performance/performance', { params })
        const newFields = { leads: (data.leads || '').toString(), closing_rate: (data.closing_rate || '').toString() }
        this.$set(this.fields, idx, newFields)
        this.$set(this.oldFields, idx, JSON.stringify(newFields))
        this.$set(this.performances, idx, this.transformPerformance(data.performance, idx))
        this.$set(this.isOpened, idx, true)
      } catch (err) {
        this.alertError(err)
      } finally {
        this.$set(this.loadget, idx, false)
      }
    },
    async updateTarget(idx, id) {
      try {
        this.$set(this.loadpost, idx, true)
        const params = {
          product_id: id,
          user_id: this.data.user_id,
          cr: +this.fields[idx].closing_rate,
          leads: +this.fields[idx].leads,
          date: YEAR_MONTH(this.filterMonths[idx]),
        }
        await komtimAxiosIns.post(`v1/talent_performance/update?${new URLSearchParams(params)}`)
        this.alertSuccess('Berhasil mengubah target')
        this.updateDetail(idx, id, true)
      } catch (err) {
        this.alertError(err)
      } finally {
        this.$set(this.loadpost, idx, false)
      }
    },
  },
}
</script>
